import { MainApi } from 'api/MainApi';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Loader from 'components/Loader/Loader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PROGRESS_TYPES } from 'utils/constants';
import type { GeoTiffFile } from 'api/schema/models/GeoTiffFile';

import FilesList from './FilesList/FilesList';

import style from './SelectFileModal.module.css';

interface IDialogProps {
    isOpen: boolean;
    missionId: number;
    onConfirmClick: (filename: string) => void; 
    onClose: () => void;
}

const SelectFileModal = ({ isOpen, onConfirmClick, onClose }: React.PropsWithChildren<IDialogProps>) => {
    const { t } = useTranslation();
    const [loadingProgress, setLoadingProgress] = useState(PROGRESS_TYPES.IDLE);
    const [files, setFiles] = useState<GeoTiffFile[]>([]);
    const [selectedFile, setSelectedFile] = useState<GeoTiffFile | undefined>(undefined);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoadingProgress(PROGRESS_TYPES.WORK);
                const fetchedFiles = await MainApi.fetchGeotiffFiles();
                setFiles(fetchedFiles);
                setLoadingProgress(PROGRESS_TYPES.SUCCESS);
            } catch (error) {
                setLoadingProgress(PROGRESS_TYPES.ERROR);
                console.error("Error fetching GeoTIFF files:", error);
                // Add UI error handling here (e.g., a toast message).
            }
        };

        if (isOpen) {
            fetchData(); // Call fetchData only when the modal opens
        } else {
            // Reset state when closing if needed:
            setSelectedFile(undefined);  // Unselect if you want.
            setLoadingProgress(PROGRESS_TYPES.IDLE);  // Optional
        }
    }, [isOpen]);  // Only re-run the effect if isOpen changes

    const handleFileSelect = (file: GeoTiffFile) => {
        setSelectedFile(file);
    };

    const handleConfirmClick = () => {
        if (selectedFile) {
            onConfirmClick(selectedFile.name); 
            onClose(); 
        }
    };

    return (
        <ConfirmDialog
            isOpen={isOpen}
            className={style.root}
            onClose={onClose}
            onConfirmClick={handleConfirmClick} 
            title="Select a GeoTIFF file"
            confirmText="Select"
            confirmButtonDisabled={!selectedFile}
        >
            <div className={style.content}>
                {loadingProgress === PROGRESS_TYPES.WORK && <Loader />}
                {loadingProgress === PROGRESS_TYPES.SUCCESS && files.length > 0 ? (
                    <FilesList files={files} onSelect={handleFileSelect} selectedFile={selectedFile} />
                ) : loadingProgress === PROGRESS_TYPES.SUCCESS ? (
                    <p>No GeoTIFF files found.</p>
                ) : null /* Don't render anything if not loaded or successful */}
            </div>
        </ConfirmDialog>
    );
};


export default SelectFileModal;
