// MapList.tsx
import React, { useEffect, useState } from 'react';

const MapList: React.FC = () => {
    const [mapUrl, setMapUrl] = useState<string | null>(null); 

    useEffect(() => {
        const fetchMapUrl = async () => {
            try {
                const response = await fetch('/api/main/get-map-folder-url/');
                const data = await response.json();
                setMapUrl(data.map_url);
            } catch (error) {
                console.error('Error fetching map URL:', error);
            }
        };

        fetchMapUrl();
    }, []);

    if (!mapUrl) {  
      return <p>Loading maps folder...</p>;
    }

    return (
        <iframe src={mapUrl} style={{ width: '100%', height: '100%', border: 'none' }} title="Maps folder" />
    );
};

export default MapList;
