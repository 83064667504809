import { FormControlLabel, Switch, TextField } from '@mui/material'
import { Button } from 'components/Button/Button'
import Checkbox from '@mui/material/Checkbox'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import style from '../MissionSettingsModal.module.css'

const weekdaysLabels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface ScheduleSettingsProps {
  initialDate: Date | null;
  initialWeekdays: boolean[]; 
  onScheduleChange: (date: Date | null, weekdays: boolean[]) => void;
}

const ScheduleSettings = ({ initialDate, initialWeekdays, onScheduleChange }: ScheduleSettingsProps) => {
    const [date, setDate] = useState<Date | null>(initialDate);
    const [weekdays, setWeekdays] = useState<boolean[]>(initialWeekdays);
    const [initialRender, setInitialRender] = useState(true)
    
    useEffect(() => {
        if (initialRender) {
          setWeekdays(initialWeekdays);
          setInitialRender(false);
        }
    }, [initialWeekdays, initialRender]);
    
    const handleDateChange = (newDate: Date | null) => {
        setDate(newDate);
        onScheduleChange(newDate, weekdays);
    };

    const handleWeekdayChange = (dayIndex: number) => {
        const updatedWeekdays = [...weekdays];
        updatedWeekdays[dayIndex] = !updatedWeekdays[dayIndex];
        setWeekdays(updatedWeekdays);
        onScheduleChange(date, updatedWeekdays);
    };

    const handleClearDate = () => {
      setDate(null);
      onScheduleChange(null, weekdays);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className={style.dateTimeContainer}>
                <DateTimePicker
                    label="Choose date and time"
                    value={date}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            sx={{
                              '& .MuiInputBase-root': { 
                                  height: 40, 
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  justifyContent: 'center',
                              },
                              '& .MuiInputLabel-root': { 
                                  // fontSize: '14px',
                                  textAlign: 'center',
                                  position: 'absolute',
                                  top: '50%', 
                                  left: '40%',
                                  transform: 'translate(-50%, -50%)',
                              },
                              '& .MuiInputLabel-shrink': { 
                                  top: '-20%', // Move label to top when active
                                  left: '5%', // Align to the left when active
                                  transform: 'none',
                                  fontSize: '12px'
                              },
                            }}
                        />
                    )}
                />
                <Button.Outlined className={style.btnClear} onClick={handleClearDate}>
                  Clear date and time
                </Button.Outlined>
            </div>


            <FormControlLabel
                control={
                    <Checkbox
                        checked={weekdays.every(Boolean)}
                        onChange={() => {
                            const allChecked = weekdays.every(Boolean);
                            const newWeekdays = weekdays.map(() => !allChecked);
                            setWeekdays(newWeekdays);
                            onScheduleChange(date, newWeekdays);
                        }}
                    />
                }
                label="Set a cleaning schedule by day of the week"
                sx={{ paddingTop: 3 }}
            />
            {weekdays.some(Boolean) && (
                <div className={style.weekdays}>
                   {weekdays.map((dayChecked, index) => (
                       <FormControlLabel
                           key={index}
                           control={<Switch checked={dayChecked} onChange={() => handleWeekdayChange(index)} />}
                           label={weekdaysLabels[index]}
                       />
                   ))}
                </div>
            )}

        </LocalizationProvider>
    );
};

export default ScheduleSettings;

