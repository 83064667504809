import { ThemeProvider, createTheme } from '@mui/material'
import { Layout } from 'components/Layout/Layout'
import { useUser } from 'hooks/user'
import Mission from 'pages/Mission/Mission'
import MissionControl from 'pages/MissionControl/MissionControl'
import MapList from 'pages/MapList/MapList'
import MissionList from 'pages/MissionList/MissionList'
import NotFound from 'pages/NotFound/NotFound'
import Robot from 'pages/Robot/Robot'
import RobotList from 'pages/RobotList/RobotList'
import NewPassword from 'pages/Signin/NewPassword/NewPassword'
import RestorePassword from 'pages/Signin/RestorePassword/RestorePassword'
import Signin from 'pages/Signin/Signin'
import { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { PATH } from './utils/constants'

const theme = createTheme({
  palette: {
    primary: {
      main: '#2E70EF',
    },
    secondary: {
      main: '#000A19CC',
    },
  },
})

function PrivatePage({ children }: { children: JSX.Element }) {
  const user = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user?.state.userLoggedIn) {
      navigate(PATH.SIGNIN)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.state.userLoggedIn])

  return children
}

function AuthPage({ children }: { children: JSX.Element }) {
  const location = useLocation()
  const user = useUser()

  if (user?.state.userLoggedIn) {
    return <Navigate to={PATH.MISSION_LIST} state={{ from: location }} />
  }

  return children
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout.PublicPage />}>
            <Route path={PATH.SIGNIN} element={<AuthPage children={<Signin />} />} />
            <Route path={PATH.RESTORE_PASSWORD} element={<AuthPage children={<RestorePassword />} />} />
            <Route path={PATH.NEW_PASSWORD} element={<AuthPage children={<NewPassword />} />} />
            <Route path={PATH.NOT_FOUND} element={<NotFound />} />
          </Route>
          <Route element={<Layout.MainPage />}>
            <Route path={PATH.MISSION_LIST} element={<PrivatePage children={<MissionList />} />} />
            <Route path={PATH.MISSION_ID} element={<PrivatePage children={<Mission />} />} />
            <Route path={PATH.ROBOT_LIST} element={<PrivatePage children={<RobotList />} />} />
            <Route path={PATH.ROBOT} element={<PrivatePage children={<Robot />} />} />
            <Route path={PATH.MISSION_CONTROL} element={<PrivatePage children={<MissionControl />} />} />
            <Route path={PATH.MAP_LIST} element={<PrivatePage children={<MapList/>} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
