import { intervalToDuration } from 'date-fns'
import { ReactComponent as ArrowSvg } from 'images/icons/arrow_select.svg'
import { useState } from 'react'
import { IRobotInfo } from 'utils/interfaces'

import style from './MissionStats.module.css'

const MissionStats = ({ robot }: { robot?: IRobotInfo }) => {
  const [isOpen, setIsOpen] = useState(false)

  const getDuration = (seconds: number) => {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
    return duration.days
      ? `${duration.days}d:${duration.hours}h:${duration.minutes}m`
      : `${duration.hours}h:${duration.minutes}m`
  }

  const missionStats = robot?.robotRaw?.missions_jobs[0]?.mission_status_last?.mission_progress
  const totalStats = missionStats?.overall_mission_feedback
  const totalPercents =
    totalStats && totalStats.progress !== undefined && totalStats.overall !== undefined && totalStats.overall
      ? Math.round(totalStats.progress / totalStats.overall * 100)
      : '-'
  const totalMeters =
    totalStats && totalStats.progress !== undefined && totalStats.overall !== undefined && totalStats.overall
      ? `${Math.round(totalStats.progress)}m/${Math.round(totalStats.overall)}m`
      : '-/-'
  const totalTime = totalStats?.forecast !== undefined && totalStats && totalStats.progress
      ? getDuration(totalStats?.forecast)
      : '-'

  const zoneStats = missionStats?.coverage_task_feedback
  const zoneMaxPossible = zoneStats && zoneStats.forecast? `${Math.round(zoneStats.forecast)}%` : '-'
  const zoneProgress = zoneStats && zoneStats.progress ? `${Math.round(zoneStats.progress)}%` : '-'
  const zoneArea = zoneStats && zoneStats.overall ? `${Math.round(zoneStats.overall)}sq m` : '-'

  const routeStats = missionStats?.moving_task_feedback
  const routeMetersTraveled = routeStats && routeStats.progress ? `${Math.round(routeStats.progress)}m` : '-'
  const routeTimeLeft = routeStats?.forecast !== undefined && routeStats && routeStats.overall ? getDuration(routeStats?.forecast) : '-'
  const routeMetersTotal = routeStats && routeStats.overall ? `${Math.round(routeStats.overall)}m` : '-'

  return (
    <div className={style.root}>
      <div className={style.additionalInfoHeader}>
        <div>
          <span className={style.semibold}>Total progress:</span>
          <span className={style.textInfo}>{totalPercents}%</span>
          <span className={style.textInfo}>{totalMeters}</span>
          <span className={style.semibold}>Time left: </span>
          <span className={style.textInfo}>{totalTime}</span>
        </div>
        <div className={style.details} onClick={() => setIsOpen(!isOpen)}>
          <span>Details</span>
          <ArrowSvg className={style.icon} />
        </div>
      </div>
      {isOpen && (
        <div className={style.twoColumns}>
          <div className={style.column}>
            <div className={style.subtitle}>Current zone</div>
            <div>
              Max possible coverage: <span className={style.bold}>{zoneMaxPossible}</span>
            </div>
            <div>
              Zone cleaned: <span className={style.bold}>{zoneProgress}</span>
            </div>
            <div>
              Zone area: <span className={style.bold}>{zoneArea}</span>
            </div>
          </div>
          <div className={style.column}>
            <div className={style.subtitle}>Current route</div>
            <div>
              Distance: <span className={style.bold}>{routeMetersTotal}</span>
            </div>
            <div>
              Distance traveled: <span className={style.bold}>{routeMetersTraveled}</span>
            </div>
            <div>
              Time left: <span className={style.bold}>{routeTimeLeft}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MissionStats
